import React, { useState, useEffect, useCallback } from 'react';
import { AlertCircle, Check, ArrowLeft, Clock, PiggyBank, Trophy } from 'lucide-react';

const steps = [
  { number: 1, label: 'Total Debt' },
  { number: 2, label: 'Credit Card Debt' },
  { number: 3, label: 'Payment' },
  { number: 4, label: 'Interest Rate' }
];

const DebtCalculator = () => {
  const [display, setDisplay] = useState('0');
  const [step, setStep] = useState(1);
  const [totalDebt, setTotalDebt] = useState('');
  const [creditCardDebt, setCreditCardDebt] = useState('');
  const [minimumPayment, setMinimumPayment] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(null);
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setDots(prev => prev.length >= 3 ? '' : prev + '.');
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleNumber = (num) => {
    setDisplay(prev => {
      if (prev === '0' && num === '0') return prev;
      const newValue = prev === '0' ? num : prev + num;
      return newValue.length <= 10 ? newValue : prev; // Limit to 10 digits
    });
  };

  const handleDelete = () => {
    setDisplay(prev => prev.length > 1 ? prev.slice(0, -1) : '0');
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleNext = useCallback(() => {
    const amount = parseInt(display);
    setLoading(true);
    
    setTimeout(() => {
      if (step === 1) {
        setTotalDebt(amount);
        setStep(2);
      } else if (step === 2) {
        setCreditCardDebt(amount);
        setStep(3);
      } else if (step === 3) {
        setMinimumPayment(amount);
        setStep(4);
      } else if (step === 4) {
        setInterestRate(amount);
        if (creditCardDebt < 15000 || minimumPayment < 20) {
          setPath('B');
        } else {
          setPath('A');
        }
      }
      setDisplay('0');
      setLoading(false);
    }, 1500);
  }, [step, display, creditCardDebt, minimumPayment]);

  const ProgressBar = React.memo(() => {
    return (
      <div className="mb-4 w-full mx-auto">
        <div className="flex justify-between items-center relative">
          {steps.map((s, index) => (
            <div key={s.number} className="flex flex-col items-center">
              <div 
                className={`w-8 h-8 md:w-10 md:h-10 rounded-full flex items-center justify-center font-bold text-sm md:text-lg
                  ${step > s.number ? 'bg-green-600 text-white' : 
                    step === s.number ? 'bg-blue-600 text-white' : 
                    'bg-gray-200 text-gray-500'}`}
              >
                {step > s.number ? <Check size={16} /> : s.number}
              </div>
              {index < steps.length - 1 && (
                <div className="absolute w-full h-1 bg-gray-200 top-4 md:top-5 -z-10" />
              )}
              <span className="text-[10px] md:text-xs mt-1 md:mt-2">{s.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  });

  const CalcButton = ({ value, onClick, className = "" }) => (
    <button
      onClick={onClick}
      className={`p-2 md:p-4 text-lg md:text-xl font-bold rounded-lg transition-all duration-200 
      active:bg-opacity-70 active:transform active:scale-95 touch-manipulation ${className}`}
    >
      {value}
    </button>
  );

  const LoadingDots = () => (
    <span className="inline-block w-16 text-left">
      {dots}
    </span>
  );

  const InfoCard = ({ icon: Icon, title, description }) => (
    <div 
      className="flex-1 bg-white rounded-lg p-4 md:p-6 shadow-lg hover:shadow-xl transition-shadow border border-gray-100"
    >
      <div className="flex justify-center mb-2 md:mb-4">
        <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-blue-100 flex items-center justify-center">
          <Icon size={20} className="text-blue-600" />
        </div>
      </div>
      <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3 text-gray-800 text-center" style={{ fontFamily: 'Inter, sans-serif' }}>
        {title}
      </h3>
      <p className="text-sm md:text-base text-gray-600 text-center">
        {description}
      </p>
    </div>
  );

  const renderVerification = () => (
    <div 
      className="bg-white p-6 md:p-8 rounded-xl shadow-xl text-center max-w-md mx-auto"
    >
      <div className="flex justify-center mb-4 md:mb-6">
        <div className="w-16 h-16 md:w-20 md:h-20 bg-blue-100 rounded-full flex items-center justify-center animate-pulse">
          <AlertCircle size={32} className="text-blue-500" />
        </div>
      </div>
      <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6" style={{ fontFamily: 'Inter, sans-serif' }}>
        Verifying Your Information<LoadingDots />
      </h2>
      <div className="space-y-3 md:space-y-4">
        <div className="h-2 md:h-2.5 bg-blue-200 rounded-full animate-pulse w-3/4 mx-auto"></div>
        <div className="h-2 md:h-2.5 bg-blue-200 rounded-full animate-pulse w-full"></div>
        <div className="h-2 md:h-2.5 bg-blue-200 rounded-full animate-pulse w-2/3 mx-auto"></div>
      </div>
      <div className="mt-6 md:mt-8 text-sm md:text-base text-gray-600 animate-pulse">Please wait while we process your request</div>
    </div>
  );

  const renderResults = () => (
    <div 
      className="bg-white p-6 md:p-8 rounded-xl shadow-xl text-center max-w-4xl mx-auto"
    >
      <div className="flex justify-center mb-4 md:mb-6">
        <div className="w-16 h-16 md:w-20 md:h-20 bg-green-100 rounded-full flex items-center justify-center">
          <Check size={32} className="text-green-500" />
        </div>
      </div>
      {path === 'B' ? (
        <>
          <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>Congratulations!</h2>
          <p className="text-lg md:text-xl mb-4 md:mb-6">You're approved to qualify for a</p>
          <div className="text-3xl md:text-4xl font-bold text-green-600 mb-4 md:mb-6" style={{ fontFamily: 'Inter, sans-serif' }}>$10,000.00</div>
          <p className="text-lg md:text-xl mb-4 md:mb-6">Personal Loan!</p>
          <button 
            className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-full font-bold text-base md:text-lg hover:bg-blue-700 transition-colors shadow-lg shadow-blue-200 touch-manipulation"
          >
            See If You Qualify
          </button>
        </>
      ) : (
        <div className="max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>Congratulations!</h2>
          <div className="mb-6 md:mb-8">
            <p className="text-lg md:text-xl text-gray-600 mb-2">You can qualify for</p>
            <p className="text-3xl md:text-5xl font-bold text-blue-600 mb-3 md:mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>
              Debt Relief
            </p>
          </div>
          
          <p className="text-sm md:text-base text-gray-600 mb-6 md:mb-8">
            Based on your input, we estimate you could save up to <span className="font-bold text-green-600">{formatCurrency(creditCardDebt * 0.4)}</span> on your current Credit Card debt.
          </p>
          
          <button 
            className="bg-blue-600 text-white px-8 md:px-12 py-3 md:py-4 rounded-full font-bold text-base md:text-lg hover:bg-blue-700 transition-colors shadow-lg shadow-blue-200 mb-10 md:mb-16 touch-manipulation"
          >
            See If You Qualify
          </button>

          <div className="flex flex-col md:flex-row gap-4 mt-8 md:mt-12">
            <InfoCard 
              icon={Clock}
              title="Quick Expert Consultation"
              description="Connect with our debt specialists today and receive your personalized relief options within minutes."
            />
            <InfoCard 
              icon={PiggyBank}
              title="Tailored Financial Plan"
              description="Get a custom solution designed around your budget, timeline, and specific financial situation."
            />
            <InfoCard 
              icon={Trophy}
              title="Fast Path to Freedom"
              description="Break free from debt with our accelerated program that could have you debt-free in as little as 24 months."
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderCalculator = () => (
    <div 
      className="bg-white rounded-xl shadow-xl w-full max-w-[320px] md:max-w-md mx-auto"
    >
      <div className="p-4 md:p-6">
        <ProgressBar key={step} />
        
        <h2 className="text-xl md:text-3xl font-bold text-center text-gray-800 mb-4 md:mb-6 leading-tight" style={{ fontFamily: 'Inter, sans-serif' }}>
          {step === 1 && "How much debt do you\ncurrently have?"}
          {step === 2 && (
            <>
              How much of your current debt is{' '}
              <span className="text-red-600">Credit Card</span> debt?
            </>
          )}
          {step === 3 && "What is your minimum\nmonthly payment?"}
          {step === 4 && "What is your average\ninterest rate?"}
        </h2>

        <div className="bg-gray-50 p-3 md:p-4 rounded-lg mb-4 md:mb-6 border-2 border-gray-200">
          <div className="text-2xl md:text-4xl font-bold text-gray-800 w-full text-right tracking-tight" style={{ fontFamily: 'Inter, sans-serif' }}>
            {step === 4 ? `${display}%` : formatCurrency(display)}
          </div>
        </div>
        
        <div className="grid grid-cols-3 gap-2 md:gap-3">
          <CalcButton value="7" onClick={() => handleNumber('7')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="8" onClick={() => handleNumber('8')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="9" onClick={() => handleNumber('9')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          
          <CalcButton value="4" onClick={() => handleNumber('4')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="5" onClick={() => handleNumber('5')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="6" onClick={() => handleNumber('6')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          
          <CalcButton value="1" onClick={() => handleNumber('1')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="2" onClick={() => handleNumber('2')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton value="3" onClick={() => handleNumber('3')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          
          <CalcButton value="0" onClick={() => handleNumber('0')} className="bg-gray-100 text-gray-800 hover:bg-gray-200" />
          <CalcButton 
            value={<ArrowLeft size={20} strokeWidth={2.5} />} 
            onClick={handleDelete} 
            className="bg-yellow-500 text-white col-span-2 hover:bg-yellow-600" 
          />
        </div>

        <button
          onClick={handleNext}
          disabled={display === '0'}
          className="w-full mt-4 md:mt-6 bg-blue-600 text-white py-3 md:py-4 rounded-lg font-bold text-base md:text-lg
          hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed
          shadow-lg shadow-blue-200 touch-manipulation"
        >
          {step === 4 ? 'Calculate' : 'Next'}
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-100 py-8 md:py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8 md:mb-12">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-3 md:mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>
            Qualify for Debt Relief in Minutes!
          </h1>
          <p className="text-lg md:text-xl text-gray-600">
            Calculate how much you could save with our Debt Relief program!
          </p>
        </div>

        {loading ? renderVerification() : path ? renderResults() : renderCalculator()}
        
        {!loading && !path && (
          <div 
            className="mt-6 md:mt-8 text-center text-gray-600"
          >
            <p className="text-xs md:text-sm">
              Your information is secure and encrypted. By continuing, you agree to our Terms of Service and Privacy Policy.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DebtCalculator;

